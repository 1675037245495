import React from 'react';
import { Link } from 'react-router-dom';
import slugify from 'react-slugify';
import fipeLogo from '../images/logo-fipe.png'
import {SITE_URL} from '../services/api';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faWhatsapp} from "@fortawesome/free-brands-svg-icons";

class AdvertCard extends React.Component {

    render() {
        let content = this.props.content;
        if(!content) {
            return <div> </div>;
        }

        let protocol = window.location.protocol;
        let slashes = protocol.concat("//");
        let host = slashes.concat(window.location.host);

        return (
            content.map(function (content, i) {

                let percent = getPercent(content.fipe_percent_off);
                let link = slugify(`/detalhes/${content.id}/${content.brand.name}-${content.fipe.model}-${content.fipe.year}`);
                let message =  `Olá, vi seu anúncio do *${content.fipe.model}* no site ${host}${link} . Tenho interesse, podemos conversar?`;
                let uriMessage = encodeURI(message);

                return <div key={i}>

                    <div className="row">
                        <div className="col-12 col-xl-3">
                            <div className="media anuncioImage">
                                <Link to={{
                                    pathname: link,
                                    content: JSON.stringify(content)
                                }}>
                                    <img className="" src={`${SITE_URL}/uploads/gallery/${content.image}`}
                                        alt="Sem imagem" />
                                </Link>
                            </div>
                        </div>

                        {/*Descrição*/}
                        <div className="col-12 col-sm-6 col-xl-5 anuncioDescription">
                            <div>
                                <Link className={'anuncioTitle'}
                                    to={{
                                        pathname: link,
                                        content: JSON.stringify(content)
                                    }}>
                                    {content.fipe.model}
                                </Link>
                            </div>

                            <div>
                                <p>{content.user.city} - {content.user.state}</p>
                            </div>

                            <div>
                                <p>{content.fipe.year} - {content.fipe.fuel}</p>
                            </div>

                            <div>
                                <p>{content.color.name}</p>
                            </div>
                        </div>

                        {/*Preços/Condições - Tela de busca/Pesquisa, area informativa do anúncio*/}
                        <div className="col-12 col-sm-6 col-xl-4 precos">
                            <p className="percentFipe" style={{color : setFipeColor(percent)}}>{content.fipe_percent_off} FIPE</p>
                            <div className="progress barProgress">
                                <div className="progress-bar small fipeBar" id="fipeBar"
                                     role="progressbar" style={{width: percent + '%', backgroundColor: setFipeColor(percent)}} aria-valuenow="0" aria-valuemin="0"
                                     aria-valuemax="100">
                                </div>
                            </div>

                            <div>
                                <p className="value">
                                    {new Intl.NumberFormat('pt-BR', {
                                        style: 'currency',
                                        currency: 'BRL'
                                    }).format(content.price)}
                                </p>
                            </div>

                            <div>
                                <p className="tabelaFipe">
                                    Tabela
                                    <img className="img-fluid" src={fipeLogo} alt="FIPE" />
                                </p>
                            </div>

                            <div>
                                <p className="fipeValue">
                                    {new Intl.NumberFormat('pt-BR', {
                                        style: 'currency',
                                        currency: 'BRL'
                                    }).format(content.fipe_price)}
                                </p>
                            </div>

                            <div className={"verDetalheDiv"}>
                                <Link
                                    to={{
                                        pathname: link,
                                        content: JSON.stringify(content)
                                    }}
                                    className="btn btn-danger btn-lg verDetalhe"
                                > </Link>

                                <a className={"btn whatsAppBtn"}
                                   href={"https://api.whatsapp.com/send?phone=55" + content.user.cell_phone + "&text=" + uriMessage}
                                   target={"_blank"}>
                                    <FontAwesomeIcon icon={faWhatsapp} size={"2x"}/>
                                </a>
                            </div>

                        </div>
                    </div>
                    <div className="col-md-12 separadorDiv">
                        <div className="dropdown-divider separador"> </div>
                    </div>
                </div>
            })
        );
    }
}

function getPercent(e) {
    return e.replace(/[%-]/g,'');
}

function setFipeColor(e) {
    let color = "black";
    if (e < 15)             color = "#D0021B";  //14% ou menos vermelho
    if (e >= 15 && e <= 16) color = "#FFAA00";  //15% ou 16%   amarelo
    if (e > 16 && e <= 19)  color = "#F78C46";  //17% até 19   laranja
    if (e > 19)             color = "#34A853";  //20% ou mais  verde
    return color;
}

export default AdvertCard;