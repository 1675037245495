import React, { Suspense }  from 'react';
import ReactDOM             from 'react-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import './index.css';
import { Router, Route }    from "react-router-dom";
import PageDetalhes         from "./Pages/Detalhes";
import PageContato          from "./Pages/Contato";
import PageAvaliacao        from "./Pages/Avaliacao";
import PageEstoque          from "./Pages/Home";
import {STORE_DIR, STORE_ID} from "./services/api";
import pageview             from './services/ga';
import history              from "./services/history";
import './services/gtm';
const Header    = React.lazy(() => import('./components/clients/' + STORE_DIR + '/Header/header'));
const Footer    = React.lazy(() => import('./components/clients/' + STORE_DIR + '/Footer/footer'));
const About     = React.lazy(() => import('./components/clients/' + STORE_ID + '/Pages/About/about'));
const Home  = React.lazy(() => import('./components/clients/' + STORE_ID + '/Pages/Home/home'));

pageview(window.location.pathname + window.location.search);

ReactDOM.render(
    <React.Fragment>
        <Router history={history}>
            <Suspense fallback={<div>Carregando...</div>}>
                <Header />
            </Suspense>

            <Suspense fallback={<div>...</div>}>
                <Route exact path={["/", "/home"]} component={Home} />
            </Suspense>

            <Suspense fallback={<div>...</div>}>
                <Route exact path={["/pesquisa/:page","/pesquisa"]} component={PageEstoque} />
            </Suspense>

            <div>
                <Route          path={"/detalhes/:id/:slug"}        component={PageDetalhes} />
            </div>

            <div>
                <Route          path={"/contato"}                   render={() => <PageContato />} />
            </div>

            <div>
                <Route          path={"/avaliacao"}                   render={() => <PageAvaliacao />} />
            </div>

            <Suspense fallback={<div>Carregando...</div>}>
                <Route          path={"/sobre-nos"}             component={About} />
            </Suspense>

            <Suspense fallback={<div>Carregando...</div>}>
                <Footer />
            </Suspense>
        </Router>
    </React.Fragment>,
    document.getElementById('body')
);