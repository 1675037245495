import React, {Suspense} from 'react';
import './index.css';
import BotaoTopo from "../../components/BotaoTopo";
import AdvertisingTop from "../../components/AdvertisingTop/advertisingTop";
import {STORE_DIR} from "../../services/api";

const FormAvaliacao = React.lazy(() => import('../../components/clients/' + STORE_DIR + '/Pages/FormAvaliacao/formAvaliacao'));

export default class PageAvaliacao extends React.Component {

    render() {
        return (
            <React.Fragment>
                <div className="col-12 page-avaliacao">
                    <AdvertisingTop/>
                </div>
                <div className={"container"}>
                    <Suspense fallback={<div>Carregando...</div>}>
                        <div className="col-12 page-avaliacao">
                            <FormAvaliacao/>
                        </div>
                    </Suspense>
                </div>
                <BotaoTopo scrollStepInPx="30" delayInMs="2" showAbove={171}/>
            </React.Fragment>
        )
    }
}